const MAX_RETRIES = 5;
const DELAY_MS = 500;

export default function retry<T>(
  fn: () => Promise<T>,
  maxAttempts = MAX_RETRIES,
) {
  const execute = async (attempt: number): Promise<T> => {
    try {
      return await fn();
    } catch (error) {
      if (attempt <= maxAttempts) {
        const nextAttempt = attempt + 1;
        const delayInMs = DELAY_MS * Math.pow(2, attempt - 1);

        return delay(() => execute(nextAttempt), delayInMs);
      } else {
        throw error;
      }
    }
  };

  return execute(1);
}

function delay<T>(fn: () => T, ms: number): Promise<T> {
  return new Promise((resolve) => setTimeout(() => resolve(fn()), ms));
}
