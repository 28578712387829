import * as React from 'react';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { getAnonymousId } from './src/analytics/anonymousId';
import retry from './src/utils/retry';
import "./src/stylesheets/tailwind.css"

// Reload the page if new data is available.
export const onServiceWorkerUpdateReady = () => window.location.reload(true);

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider>{element}</PrismicPreviewProvider>
);

export const onClientEntry = () => {
  // Set anonymousId for Segment events
  if (process.env.NODE_ENV === 'production') {
    const anonymousId = getAnonymousId();
    retry(() => window.analytics.setAnonymousId(anonymousId), 4);
  }
}