/*
 * The code is extracted and adapted from:
 * https://github.com/brexhq/credit_card/blob/main/product/dashboard/src/features/Analytics/helpers/anonymousId.ts
 */
import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';

/**
 * We use a cookie for the anonymous ID so we can share it seamlessly between
 * app.pry.co and pry.co. This requires actually computing the
 * correct root domain to set for the cookie.
 */
const cookieRootDomain = (host: string): string => {
  if (host.startsWith('localhost')) {
    return 'localhost';
  }
  if (host.endsWith('staging.pry.co')) {
    return '.stg.pry.co';
  }
  if (host.endsWith('pry.co')) {
    return '.pry.co';
  }
  return '';
};

/*
 * The anonymousId is stored in a cookie so that it can be shared across
 * the landing page and the app. If you need to change anything in this file,
 * please make sure that the changes are reflected in `budget-client`.
 */
const anonymousIdStorageKey = 'pryAnonymousId';

/**
 * Generate a UUID for identifying anonymous users, store it in a cookie, and
 * refresh the expiry date (if it already exists).
 *
 * This anonymous ID is used across LaunchDarkly, LogRocket, Sentry, and
 * Segment. It's set to persist for 10 years (indefinitely).
 */
export const getAnonymousId = (): string => {
  const existingAnonymousId = Cookies.get(anonymousIdStorageKey);
  const anonymousId = existingAnonymousId || uuid();

  // Refresh the cookie regardless if it already exists
  Cookies.set(anonymousIdStorageKey, anonymousId, {
    expires: 365 * 10,
    domain: cookieRootDomain(window.location.host),
  });
  return anonymousId;
};
