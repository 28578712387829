module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"prydocs","accessToken":"MC5ZT2lFbWhBQUFDSUFrcThw.77-977-9JSHvv71077-977-9IGrvv71277-9JQJbWu-_ve-_vQXvv73vv71RNlJ977-977-977-9KhoH","imageImgixParams":{"auto":"compress,format","fit":"max","lossless":true,"max-w":1280,"q":65},"imagePlaceholderImgixParams":{"w":100,"blur":15,"q":50},"promptForAccessToken":true,"apiEndpoint":"https://prydocs.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"GbqUXbi3UyIxMZLlRTWo6KsLtOIYf07qcITJ50kgVnc","apiHost":"https://app.posthog.com","head":true,"isEnabledDevMode":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-4LTP6XJKMP"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pry","short_name":"Pry","description":"Pry helps you keep track of your business with forecasting, bookkeeping, modeling, scenarios and business intelligence all in one place. Make faster decisions with the insights that actually matter.","lang":"en","start_url":"/","background_color":"#ffffff","theme_color":"#3d7dff","display":"minimal-ui","icon":"src/static/images/favicons/favicon-32x32.png","icons":[{"src":"/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicons/favicon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/favicon-128x128.png","sizes":"128x128","type":"image/png"},{"src":"/favicons/favicon-maskable-128x128.png","sizes":"128x128","type":"image/png","purpose":"any maskable"},{"src":"/favicons/favicon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/favicon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9336f9b24d0b9e63cf57a961fec4fe52"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"tfn8kid"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P3TS2VQ","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"YZQ07KbtHc5BvI9NlDhdVACGWdeHKE01","trackPageImmediately":false,"trackPageOnlyIfReady":true,"trackPageOnRouteUpdate":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
